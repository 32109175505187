class Actual {
    out1 = false;
    out2 = false;
    voltage1 = 0.0;
    voltage2 = 0.0;
    voltage3 = 0.0;
    current1 = 0.0;
    current2 = 0.0;
    current3 = 0.0;
    temperature = 0.0;
    humidity = 0.0;
    isDay = false;
    cloudcover = 0.0;

    constructor(json) {
        if (!json) return;

        //? Stato pannelli
        this.out1 = json.out1;
        this.out2 = json.out2;
        this.voltage1 = json.voltage1;
        this.voltage2 = json.voltage2;
        this.voltage3 = json.voltage3;
        this.current1 = json.current1;
        this.current2 = json.current2;
        this.current3 = json.current3;

        //? Weather
        this.temperature = json.temperature;
        this.humidity = json.humidity;
        this.isDay = json.isDay;
        this.cloudcover = json.cloudcover;

        return;
    }
}

export default Actual;