import logoOn from './images/cristallo_acceso.png';
import logoOff from './images/cristallo_spento.png';
import logohype from './images/hype.png';
import axios from 'axios';
import './App.css';

import { useState, useEffect, useRef } from 'react';
import Actual from './models/actual';

function App() {
  const [data, setData] = useState(new Actual());

  const isInitialized = useRef(false);

  const interval = useRef(null);

  useEffect(() => {
    if (!isInitialized.current) {
      getDashboard();
    }

    if (!interval.current) interval.current = setInterval(getDashboard, 10000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    }
  }, []);

  async function getDashboard() {
    isInitialized.current = true;

    const baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3000/api/v1' : 'https://cristallov2.hype-design.it/api/v1';

    await axios.get(`${baseUrl}/dashboard/all`).then((v) => {
      setData(new Actual(v.data));
    }).catch((e) => console.log(e));
  }

  return (
    <div className="App">
      <div id='Body'>
        <img src={data.out1 && data.out2 ? logoOn : logoOff} style={{ width: '60%', marginTop: '5%' }} />
        <img src={logohype} style={{ width: '30%', marginTop: '5%', alignSelf: 'end' }} />
        <div id="Row">
          <p id="ValueTitle" style={{ marginTop: '7%', marginBottom: '5%' }}>Energia prodotta: {(data.voltage1 * data.current1 + data.voltage2 * data.current2).toFixed(2) + ' W'}</p>
          <div id="ValueContainer">
            <p id="ValueTitle">Stato luci</p>
            <p id="ValueText">Sopra: {data.out1 ? 'Accese' : 'Spente'}</p>
            <p id="ValueText">Sotto: {data.out1 ? 'Accese' : 'Spente'}</p >
          </div >
          <div id="ValueContainer">
            <p id="ValueTitle">Fotovoltaico 1</p>
            <p id="ValueText">Tensione: {(data.voltage1).toFixed(2) + ' V'}</p>
            <p id="ValueText"> Corrente: {(data.current1).toFixed(2) + ' A'}</p >
            <p id="ValueText"> Potenza: {(data.voltage1 * data.current1).toFixed(2) + ' W'}</p >
          </div >
          <div id="ValueContainer">
            <p id="ValueTitle">Fotovoltaico 2</p>
            <p id="ValueText">Tensione: {(data.voltage2).toFixed(2) + ' V'}</p>
            <p id="ValueText"> Corrente: {(data.current2).toFixed(2) + ' A'}</p >
            <p id="ValueText"> Potenza: {(data.voltage2 * data.current2).toFixed(2) + ' W'}</p >
          </div>
          <div id="ValueContainer">
            <p id="ValueTitle">Consumo</p>
            <p id="ValueText">Tensione: {(data.voltage3).toFixed(2) + ' V'}</p>
            <p id="ValueText"> Corrente: {(data.current3).toFixed(2) + ' A'}</p >
            <p id="ValueText"> Potenza: {(data.voltage3 * data.current3).toFixed(2) + ' W'}</p >
          </div>
        </div >
        <div id="Row" style={{ marginTop: '0' }}>
          <p id="ValueTitle">AMBIENTE</p>
          <p id="ValueText">Temperatura: {data.temperature} °C</p>
          <p id="ValueText">Umidità relativa: {data.humidity} %</p >
          <p id="ValueText">Giorno / Notte: {data.isDay ? 'Giorno' : 'Notte'}</p >
          <p id="ValueText">Nuvolosità: {data.cloudcover} %</p >
        </div>
      </div >
    </div >
  );
}

export default App;
